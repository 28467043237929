let Speedtest = require('../../public/speedtest_safari');

var server_details = {	
	name:"Local dev server", 
	server:`//omega-video-conf.signzy.app/speedtest/`, //URL to the server. // at the beginning will be replaced with http:// or https:// automatically
	dlURL:"garbage",  //path to download test on this server (garbage.php or replacement)
	ulURL:"empty",  //path to upload test on this server (empty.php or replacement)
	pingURL:"empty",  //path to ping/jitter test on this server (empty.php or replacement)
	getIpURL:"getIP"  //path to getIP on this server (getIP.php or replacement)
};

var s= null;

function startStop(domain){ //start/stop button pressed
	if(s.getState()==3){
		//speedtest is running, abort
        console.log("Aborting:::::::::::::::::::::::::");
		s.abort();
	}else{
		//test is not running, begin
		s.start(domain);
        console.log("Running....");
	}
}

function organizeData(speedTestResult) {

  let internetSpeed = {
      speedBps: speedTestResult.dlStatus * 1e6,
      speedKbps: speedTestResult.dlStatus * 1e3,
      speedMbps: speedTestResult.dlStatus,
      Download:  speedTestResult.dlStatus,
      Jitter: speedTestResult.jitterStatus,
      Ping: speedTestResult.pingStatus,
      uploadSpeedBps: speedTestResult.ulStatus * 1e6,
      uploadSpeedKbps: speedTestResult.ulStatus * 1e3,
      Upload: speedTestResult.ulStatus
  }
  return internetSpeed;
}

async function checkIfSafari() {
    let isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
                   navigator.userAgent &&
                   navigator.userAgent.indexOf('CriOS') == -1 &&
                   navigator.userAgent.indexOf('FxiOS') == -1;
    return isSafari;
}

async function getDomain() {
  if (document.getElementsByTagName('iframe')) {
    let iframe = document.getElementsByTagName('iframe');
    for(let i = 0 ; i < iframe.length ; i++) {
      if(iframe[i].id == 'videoVerification') {
        return 'https://' + document.getElementsByTagName('iframe')[i].src.split('/')[2];
      }
    } 
  }
  return undefined;
}


async function calculateSpeed(whoami, cb) {
    try {
      let host = window.location.origin.split("//")[1];
      let isSafari = await checkIfSafari();
      let domain = await getDomain() || window.location.origin;

      if(window.Worker && !isSafari) {
          let worker = new Worker(`${domain}/internetSpeed_worker.js`);
          worker.onmessage = function(e) {
            if (e && e.data && e.data.status === "failed") {
              console.log("Internet speed failed");
              cb({"status" : "failed"});
            } else {
              cb(e.data);
            }
            worker.terminate();
          }
          worker.postMessage({
            whoami : whoami,
            host : host
          });
        } else if(window.Worker) {
          console.log("for testing purpose::");
          MeasureConnectionSpeed(host, domain).then((result) => {
            cb(result);
          }).catch(err => {
            throw err;
          })
        }
        else {
          console.log("Worker is not supported");
          throw "Worker not supported by the browser";
      }
    } catch(err) {
      console.log(err);
      return {
        status : "failed"
      }
    }
}

function MeasureConnectionSpeed(host, domain) {
  return new Promise(function(resolve, reject) {
      try {
          s=new Speedtest(); //create speedtest object
          server_details.server = `//${host}/speedtest/`
          s.setSelectedServer(server_details);

          //callback to get the data
          s.onupdate=function(data){ }

          //callback for test ended/aborted
          s.onend=function(aborted, internetSpeed, worker){ 
            if(worker) worker.terminate();
            if(internetSpeed && internetSpeed.abruptCancel) {
              resolve({"status" : "failed"});
            } else {
            let speedTestResult = organizeData(internetSpeed);
            console.log("Speed Test Result:::", speedTestResult);
            resolve(speedTestResult);
            }
          }
          startStop(domain);
      } catch(err) {
          console.log("Error getting internet speed:::", err);
          reject({"status": "failed"});
      }
        
  });
}

module.exports = {
    calculateSpeed
}