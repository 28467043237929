import axios from "axios";
import SocketIO from "socket.io";
// let config = require("../config");
import { report_url, report_endPoints, base_url,end_points} from "../config";

// REASON for commenting: new callhistory document is being created at time of verify call
// let getInitiationId =  (socket,callId) => {
//     try {
//         socket.emit("createReport",{ "callId": `${callId}`});

//         //removed api hits and converted to socket
//         // const initiationId = await axios.post(`${report_url}${report_endPoints.new}`, { "callId": `${callId}` })
//         // return initiationId.data.initiationId
//     }
//     catch (err) {
//         console.log(err);
//     }

// }
const axiosInstance = window.axiosInstance;
const updateCallHistoryWithRetry = async (dataToBeUpdated, retries = 2) => {
    for (let attempt = 1; attempt <= retries; attempt++) {
        try {
            console.log(`Attempt ${attempt}: updating call history...`, dataToBeUpdated);
            await axiosInstance.post(base_url + end_points.updateCallHistory, { dataToBeUpdated });
            console.log("Call history updated successfully.");
            break; // Exit loop on successful API call
        } catch (error) {
            console.log(`Attempt ${attempt} failed: `, error);
            if (attempt === retries) {
                console.error("All retry attempts failed.");
            }
        }
    }
};
let logReportData =  (socket,initiationId,data)=>  {
    const body = { initiationId, formData: data };
    try {
        console.log("in functions")
        
            //end user will provide update via socket to scheduling
            socket.emit("updateReport",body, (response)=>{
                //This is a custom event that is triggered after Landing page is reported to MIS
                if(response)
                {
                    if(body.formData.onPage && body.formData.onPage == "Landing" && response == true) {
                        eventBus.$emit("landing-page-mis-triggered");
                    }
                    //This is a custom event that is triggered after Prerequisite check is passed and reported to MIS
                    if(body.formData.currentProcess && body.formData.currentProcess ==  "geo location fetched passed" && response == true){
                        eventBus.$emit("completed-prerequisites-check");
                    }
                }
                else {
                    // If socket fails, trigger the retry mechanism for the API call
                    console.log("Socket emit failed; starting retry for updateCallHistory.");
                    updateCallHistoryWithRetry(body);
                }
            });
    }
    catch (err) {
        console.log('Socket emit failed; starting retry for updateCallHistory (inside catch).',err);
        updateCallHistoryWithRetry(body);
    }
}

export {
    logReportData
}