const axiosInstance = window.axiosInstance;
import axios from "axios";
import { end_points } from "./../config"
const checkDataSafety = (obj, props, fallbackString) => {
    var splited = props.split('.');
    var temp = obj;
    for (var index in splited) {
        if (typeof temp[splited[index]] === 'undefined')
            return fallbackString || "";
        temp = temp[splited[index]]
    }
    if (temp && temp.toString().trim()) {
        temp = temp.toString().trim();
        return temp;
    } else {
        return fallbackString;
    }
}

const checkImageSafety = (data) => {
    if (data) {
        if (!data.includes("data:image")) {
            return `data:image/jpeg;base64,${data}`
        }
        return data;
    } else {
        return "";
    }
}

const parseData = (data) => {
    return {
        "data": {
            "name": checkDataSafety(data, "result.KycData.Name.fullName", "N/A"),
            "Birth Date": checkDataSafety(data, "result.KycData.Demographics.dateOfBirth", "N/A"),
            "Gender": checkDataSafety(data, "result.KycData.Demographics.gender", "N/A"),
            "Address": Object.keys(data.result.KycData.Address).map(x => data.result.KycData.Address[x]).filter(x => x).join(" "),
            "District": checkDataSafety(data, "result.KycData.Address.district"),
            "State": checkDataSafety(data, "result.KycData.Address.state"),
            "City": checkDataSafety(data, "result.KycData.Address.villageTownCity"),
            "Post Office": checkDataSafety(data, "result.KycData.Address.postOffice"),
            "PinCode": checkDataSafety(data, "result.KycData.Address.pinCode"),
            "Application ID": checkDataSafety(data, "result.KycData.applicationId","N/A"),
            "Aadhaar Reference Number": checkDataSafety(data, "result.KycData.aadhaarReferenceNumber","N/A")
        },
        "image": checkImageSafety(checkDataSafety(data, "result.KycData.photo"))
    }
}
let callAadhaarVault = async ({ appRefNum }) => {
    try {
        const response = await axiosInstance.post(end_points.custom_service_caller("citi", "aadhaar"), {
            applicationId: appRefNum
        });
        // const response = await axios.get("https://gist.githubusercontent.com/mrsid96/a95d0478cc2404acfdcb173642b36f5a/raw/2eb81e9cf4d4bf95844476ee22ab8c66734d04f9/test.json")
        return parseData(response.data.data);
    } catch (error) {
        console.log(error);
        return {
            "data": {
                "name": "N/A",
                "Birth Date": "N/A",
                "Address": "N/A",
                "District": "N/A",
                "State": "N/A",
                "PinCode": "N/A"
            }
        };
    }
}

const getAppRefNumber = (documents) => {
    let appRefNum = undefined;
    documents.forEach(document => {
        for (let i in document.requestData) {
            if (i == "appRefNum") {
                appRefNum = document.requestData[i]
            }
        }
    });
    return appRefNum;
}

export {
    callAadhaarVault,
    getAppRefNumber
}

